const TOKEN_KEY = "access_token";
const TOKEN = "token";
const USER_INFO = "userInfo";
const USER_PROFILE_KEY = "user_profile";
const COUNTRY = "country";
const LANGUAGE = "language";
const CITY = "city";
const PWA = "IS_PWA";

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/

const UserData = {
  getUserProfile() {
    return localStorage.getItem(USER_INFO);
  },

  saveUserProfile(userProfile) {
    localStorage.setItem(USER_INFO, userProfile);
  },

  removeUserProfile() {
    localStorage.removeItem(USER_INFO);
  },
};

export default { UserData };
