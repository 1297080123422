import axiosInstance from "./Api";

export default {

//   async getSingleProductDetails(payload) {
//     let response = await axiosInstance().get(`/product/${payload}`);
//     return response;
//   },

  async getAllUsersNotifiaction(){
    let response = await axiosInstance().get(`/notifications`);
    return response;
  },

  async getAllAdminNotifiaction(){
    let response = await axiosInstance().get(`/notifications/admin_notifications`);
    return response;
  },

  async createNotification(){
    let response = await axiosInstance().get(`/notifications/createNofification`);
    return response;
  },

};
